.App {
  text-align: center;
  background-color: #ffffff;
}

.App-header {
  background-color: #fff;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #black;
  overflow: hidden;
}

.calcH1 {
  font-family: 'Roboto';
}

.topper {
  position: absolute;
  top: 5vh;
  left: 0;
  right: 0;
  min-width: 100%;
  display: flex;
  align-items: center;
}

.topper h1 {
  margin: 0 auto;
  font-size: 48px;
  font-size: 15vw;
  text-align: left;
  line-height: 56px;
  line-height: 15vw;
  font-family: Spectral;
  overflow: visible;
}

@media only screen and (min-width: 800px) {
  .App-header h1 {
    font-size: 90px;
    line-height: 90px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .App-header h1 {
    font-size: 120px;
    line-height: 120px;
  }
}

.main-dog {
  height: 100vh;
}

.Map {
  font-family: 'Spectral';
  background-color: #c4d7e6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.Map h2 {
  font-size: 2.5rem;
}

.Map img {
  max-height: 100vh;
  max-width: 80%;
}

.Map a {
  font-family: 'Open Sans Condensed';
  border: 3px solid black;
  font-weight: 300;
  padding: 15px 25px;
  text-decoration: none;
  color: black;
  font-size: 2rem;
  transition: 0.5s;
}

@media only screen and (min-width: 900px) {
  .Map h2 {
    font-size: 3.5rem;
  }
  .Map a {
    font-size: 3.5rem;
  }
}

.Map a:hover {
  color: #c4d7e6;
  background-color: black;
  border-color: black;
}

.Map a:last-of-type {
  margin-top: 20px;
  padding: 0px;
  border: none;
}

.Map a:last-of-type:hover {
  color: black;
  background-color: #c4d7e6;
  border-color: #c4d7e6;
}

.Free {
  background-color: #f3f6fb;
  max-width: 900px;
  min-height: 100vh;
  display: block;
  margin:auto;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.Free h2 {
  margin-left: 10px;
  font-size: 2rem;
  text-align: left;
}

.Free h2 span {
  font-size: 2rem;
  font-family: 'Open Sans Condensed'
}

.fear-free {
  width: 720px;
  max-width: 80%;
}

.centered {
  align-items: center;
}

.pad-james {
  padding: 40px 0px 20px 0px;
  margin: 0 auto;
}

.james {
  display: block;
  margin: auto;
  border-radius: 50%;
  width: 540px;
  max-width: 60%;
}

@media only screen and (min-width: 600px) {
  .Free h2 {
    font-size: 2.8rem;
  }
  .Free h2 span {
    font-size: 2.8rem;
  }
}

@media only screen and (min-width: 900px) {
  .Free h2 {
    font-size: 3.5rem;
    font-family: 'Spectral';
  }
  .Free h2 span {
    font-size: 3.5rem;
  }
}

.Projects {
  font-family: 'Open Sans Condensed';
  background-color: #fff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.Projects a {
  font-family: 'Open Sans Condensed';
  border: 3px solid black;
  font-weight: 300;
  padding: 15px 25px;
  margin: 10px 30px;
  text-decoration: none;
  color: black;
  font-size: 2rem;
  transition: 0.5s;
}

.LinkBlue {
  font-family: 'Noto Sans JP';
  font-size: 1.5em;
}

.LinkButton {
  font-family: 'Open Sans Condensed';
  border: 3px solid black;
  font-weight: 300;
  padding: 15px 25px;
  margin: 10px 30px;
  text-decoration: none;
  color: black;
  font-size: 2rem;
  transition: 0.5s;
}

  .Projects h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: underline;
  }

@media only screen and (min-width: 900px) {
  .Projects h2 {
    font-size: 3.5rem;
  }
  .Projects a {
    font-size: 3.5rem;
  }
  .LinkButton {
    font-size: 3.5rem;
  }
}

.Projects a:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.LinkButton:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.small-icon-cat1 {
  padding-top: 60px;
  width: 65vw;
  max-width: 184px;
  max-height: 202px;
}

.small-icon-heart {
  width: 65vw;
  max-width: 168px;
  max-height: 204px;
}

.small-dog {
  width: 50vw;
  max-width: 151px;
  max-height: 182px;
  padding-bottom: 80px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.home-button {
  color: black;
  font-size: 1.8rem;
  border: 1px solid ;
  border-radius: 50%;
  padding: 5px;
}
